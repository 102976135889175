import React, { useCallback, useEffect, useMemo, useState } from "react";

import SocketIOClient from "socket.io-client";

const baseApiUrl = "https://api.imsohungry.com/";

export const SocketContext = React.createContext({
  isConnected: false,
  socket: null,
  socketDisconnect: () => {},
});
export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const socketInt = SocketIOClient(baseApiUrl, {
      transports: ["polling"],
    });

    console.log(socketInt , "sdaf");

    connectSocket(socketInt);

    socketInt.on("error", (error) => {
      setIsConnected(false);
      console.error("Socket connection error:", error);
    });

    setSocket(socketInt);
    return () => {
      if (socketInt) {
        socketInt.disconnect();
        setIsConnected(false);
      }
    };
  }, []);

  const connectSocket = (socketInt) => {
    socketInt.on("connect", (response1) => {
      console.log(response1, "socketData 1");
      setIsConnected(true);
      const userId = localStorage.getItem("_id");
      if (userId) {
        socketInt.emit("adminsocket", { adminId: userId }, (response) => {
          console.log("socketData 1", response);
        });
      }
      console.log("Socket connected");
    });
  };

  const socketDisconnect = useCallback(() => {
    // socket.emit("disconnectCus", { id: localStorage.getItem("userId") });
  }, [socket]);

  const values = useMemo(
    () => ({
      isConnected,
      socket,
      socketDisconnect,
    }),
    [isConnected, socket, socketDisconnect]
  );


  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
}
