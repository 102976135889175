import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter, withRouter } from "react-router-dom";

import configureStore, { history } from "./store";
import App from "./containers/App";
import { APP_NAME, PANEL_NAME } from "./ApiUrl";
import { SocketProvider } from "./socket";

export const store = configureStore();

class MainApp extends React.Component {
  componentDidUpdate() {
    let hash = this.props.location.hash;
    hash = hash.split("/");
    if (hash.length === 4) {
      let title = hash[2];
      title = title.charAt(0).toUpperCase() + title.slice(1);
      document.title = `${APP_NAME} | ${title}`;
    }
  }
  render() {
    return (
      <SocketProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <HashRouter>
                <Route path="/" component={App} />
              </HashRouter>
            </Switch>
          </ConnectedRouter>
        </Provider>
      </SocketProvider>
    );
  }
}

export default withRouter(MainApp);
