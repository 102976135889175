// Api BASE URLs
export const API_URL = "https://api.imsohungry.com";

////***************TESTING URL********************/

// export const API_URL = "https://staging-api.imsohungry.com/";

export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem("user_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const Numeric = /^[0-9]+$/;
export const Mobile_Pattern = new RegExp(/^[0-9\b]+$/);
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.";

export const APP_NAME = "I'm So Hungry";
export const PANEL_NAME = "Admin";
// AUTH URL
export const API_LOGIN = "/admin/adminLogin";

// DASHBOARD
export const DASHBOARD = "/adminDashboard/getDashboardList";

// File Upload
export const FILE_UPLOAD = "/adminSetting/uploadFile";

// Stores
export const API_GET_STORES_URL = "/store/";
export const API_GET_STORES_BY_ID = "/store/getstorebyId/";
export const API_ADD_STORES = "/store/register/";
export const API_EDIT_STORES = "/store/updatestore/";
export const API_ADD_STORE_BANKAC = "/store/addaccount/";
export const API_ADD_STORE_DOCS = "/store/addStoreDoc/";
export const API_GET_STORE_DOCS = "/store/getStoreDoc/";
export const API_BUSINESS_TYPE_GET = "/admin/titlelist";

// Store's Categories
export const API_GET_STORES_CATEGORIES = "/storecategory/";
export const API_GET_STORES_CATEGORIE_BY_ID = "/storecategory/edit/";
export const API_ADD_STORES_CATEGORIE = "/storecategory/add/";
export const API_EDIT_STORES_CATEGORIE = "/storecategory/update";
export const API_DELETE_STORES_CATEGORIE = "/storecategory/remove";

export const API_CATEGORY_ADD_URL = "/category/add";
export const API_CATEGORY_LIST_URL = "/category";
export const API_CATEGORY_GET_URL = "/category/addsubcategory";
export const API_CATEGORY_UPDATE_URL = "/category/updatesubcategory";
export const API_CATEGORY_DELETE_URL = "/category/remove";

export const API_GET_SUBCATEGORY = "/category/allsubcategory";
export const API_GET_SUBCATEGORY_BY_ID = "/category/editsubcat/";
export const API_ADD_SUBCATEGORY = "/category/addsubcategory";
export const API_EDIT_SUBCATEGORY = "/category/updatesubcategory";

export const API_GET_RESTAURANTS_URL = "/admin/getRestaurantWithFilter";
export const API_GET_RESTAURANTS_All = "/admin/restaurantlist";
export const API_RESTAURANTS_VIEW_URL = "/admin/restaurant/";

export const API_RESTAURANTS_REPORT_VIEW_URL =
  "/admin/driver/getReturantReport/";

export const API_GET_FRESHMARKET_CATEGORY_URL = "admin/freshCategoryList/";

export const API_RESTAURANTS_MENU_UPDATE_URL =
  "/admin/restaurant/changeMenuItemOrder";
export const API_RESTAURANTS_ITEM_UPDATE_URL =
  "/admin/restaurant/changeItemsOrder";

export const API_RESTAURANTS_DELETE_URL = "/admin/restaurant/remove";
export const API_ADD_RESTAURANT = "/admin/addrestaurant";
export const API_EDIT_RESTAURANT = "/admin/updaterestaurant";
export const API_UPDATE_RESTAURANT_STATUS = "/admin/updateStatusrestaurant";
export const API_GET_MENU_BY_CATEGORY = "/admin/menu/items";

export const API_RESTAURANTS_STATUS_URL = "/admin/restaurantstatus";

export const API_RESTAURANTS_ADD_URL = "/admin/restaurant/add";
export const API_GET_RESTAURANTS_PAYMENTS = "/admin/restaurantpaymentshistory/";
export const API_RESTAURANTS_PAYMENTS = "/admin/paytorestaurant/";
// Category
export const API_GET_CATEGORY_URL = "/category/";
export const API_GET_CATEGORY = "/category/edit/";
export const API_ADD_CATEGORY = "/category/add/";
export const API_EDIT_CATEGORY = "/category/update/";
export const API_DELETE_CATEGORY = "/category/remove/";

// Collection
export const API_GET_COLLECTION_URL = "admin/titleGetallByfilter";
export const API_ADD_COLLECTION = "admin/addtitle";
export const API_GET_COLLECTION = "admin/titledetails/";
export const API_EDIT_COLLECTION = "admin/updatetitle";
export const API_DELETE_COLLECTION = "admin/removetitle";

// Bkogs
export const API_GET_BLOG_URL = "admin/blog/getWithFilter";
export const API_ADD_BLOG = "admin/blog/add";
export const API_GET_BLOG = "admin/blog/getById/";
export const API_UPDATE_BLOG = "admin/blog/update/";
export const API_DELETE_BLOG = "admin/blog/remove/";

// Dispute URLs
// export const API_GET_DISPUTE_TRIPS = "/disputes/getLoad/";
export const API_GET_DISPUTE_TRIPS = "/dispute/all/";
export const API_GET_DISPUTE_BY_ID = "/dispute/admin/detail/";
export const API_DISPUTE_PAYMENTS = "/dispute/admin/refund/";

// Notes
export const API_GET_NOTES_URL = "/admin/note/byType";
export const API_DELETE_NOTES = "/admin/note/remove";
export const API_EDIT_NOTES = "/admin/note/edit/";
export const API_ADD_NOTES = "/admin/note/add";
export const API_GET_NOTES_BY_ID = "/admin/note/getByid/";
export const API_GET_NOTES_BY_TYPE = "/admin/note/byType/";
export const API_GET_DRIVER_REPORTS = "/admin/driver/getDriverReport/";
// export const API_GET_DRIVER_REPORT = "admin/driver/getDriverReport";

// Menu Item
export const API_GET_MENU_URL = "/admin/menuitem/";
export const API_GET_MENU_BY_ID = "/admin/menuitem/view/";
export const API_ADD_MENU = "/admin/menuitem/add/";
export const API_EDIT_MENU = "/admin/menuitem/update/";
export const API_DELETE_MENU = "/admin/menuitem/remove/";

// Fresh Market
export const API_GET_FRESHMARKET_URL = "/admin/getallfreshMarketItems/";
export const API_GET_FRESHMARKET_BY_ID = "/admin/getfreshmarketItemById/";
export const API_ADD_FRESHMARKET = "/admin/addFreshmarketItem/";
export const API_EDIT_FRESHMARKET = "/admin/editFreshmarketItem/";
export const API_DELETE_FRESHMARKET = "/admin/deleteFreshmarketItem/";

// Fresh Market Categories
export const API_GET_FRESHMARKET_CATEGORIES_URL = "/admin/freshCategoryList/";
export const API_GET_FRESHMARKET_CATEGORIES_BY_ID = "/admin/freshCategory/";
export const API_ADD_FRESHMARKET_CATEGORIES = "/admin/addFreshCategory/";
export const API_EDIT_FRESHMARKET_CATEGORIES = "/admin/updateFreshCategory/";
export const API_DELETE_FRESHMARKET_CATEGORIES = "/admin/remove/";

export const API_GET_GIFS = "/admin/getallgif/";
export const API_ADD_GIF = "/admin/addgif/";
export const API_EDIT_GIF = "/admin/changestatus/";
export const API_GET_GIF_BY_ID = "/admin/getgifbyId/";
export const API_REMOVE_GIF = "/admin/removegif/";

export const API_GET_COMPARE_PRICES = "/admin/getallByfilter/";
export const API_EDIT_COMPARE_PRICES = "/admin/editprice/";

//Addons Category

export const API_GET_ADDONS_CATEGORY = "/category/addonscategorylisting";
export const API_ADD_ADDONS_CATEGORY = "category/addonscategoryadd";
export const API_EDIT_ADDONS_CATEGORY = "/category/addonscategoryupdate";
export const API_GET_ADDONS_CATEGORY_BY_ID = "/category/addonscategory/";
export const API_REMOVE_ADDONS_CATEGORY = "/category/addonscategoryremove";

export const API_ADD_UNIT = "/adminUnit/addUnit/";
export const API_EDIT_UNIT = "/adminUnit/UpdateUnitData/";
export const API_GET_UNITS = "/adminUnit/getUnitList/";
export const API_GET_ENABLE_UNITS = "/adminUnit/getEnabledUnitList/";
export const API_GET_UNIT_BY_ID = "/adminUnit/getUnitDetailsById/";
export const API_REMOVE_UNIT = "/adminUnit/removeUnitData/";

export const API_CUISINES_LIST_URL = "/admin/cuisines";
export const API_CUISINES_ADD_URL = "/admin/cuisines/add";
export const API_CUISINES_GET_URL = "/admin/cuisines/view";
export const API_CUISINES_UPDATE_URL = "/admin/cuisines/update";
export const API_CUISINES_DELETE_URL = "/admin/cuisines/remove";

export const API_PROMOTIONS_LIST_URL = "/admin/promotions";
export const API_PROMOTIONS_ADD_URL = "/admin/promotions/add";
export const API_PROMOTIONS_GET_URL = "/admin/promotions/view";
export const API_PROMOTIONS_UPDATE_URL = "/admin/promotions/update";
export const API_PROMOTIONS_DELETE_URL = "/admin/promotions/remove";
// Store's Items
export const API_GET_STORES_ITEMS = "/admin/allProducts/";
export const API_GET_FRESH_MARKET_ITEMS = "/admin/getallfreshMarketItems/";
export const API_GET_STORES_ITEM_BY_ID = "/admin/getproductbyid/";
export const API_ADD_STORES_ITEM = "/admin/addProductsbyAdmin";
export const API_EDIT_STORES_ITEM = "/admin/updateproductbyAdmin";
export const API_DELETE_STORES_ITEM = "/admin/deleteProductsByAdmin";
export const API_UPDATE_STORE_ITEM_STATUS = "/store/updateItemStatus";
export const API_UPDATE_STORE_ITEM_ACTIVE_STATUS =
  "/admin/updateproductStatusbyAdmin";

// Drivers
export const API_GET_DRIVER_URL = "/admin/driver/driversList/";
export const API_GET_DRIVER = "/admin/driver/getDriverById/";
export const API_ADD_DRIVER = "/driver/registerbyadmin/";
export const API_EDIT_DRIVER = "/admin/driver/updateDriver/";
export const API_DELETE_DRIVER = "/admin/driver/deleteDriver/";
export const API_DRIVER_PAYMENTS =
  "/admin/driver/getAllDriverTransactionsById/";
export const GET_DRIVER_ORDERS = "/admin/driver/orderList";
export const API_STATUS_CHANGE_RIDER = "/admin/driver/statusChange/";
export const API_BLOCK_RIDER = "/admin/driver/blockUnblock/";
export const API_ORDER_DETAILS = "/admin/driver/orderDetails";
export const API_DRIVER_STATUS_CHANGE = "/admin/driver/statusChange";

// dispatcher

export const DISPATCHER_REQUEST_LIST = "admin/dispatch/request/list";
export const GET_DISPATCHER_DRIVER_LIST =
  "admin/dispatch/request/nearbydrivers";
export const ASSIGN_DISPATCHER_DRIVER = "admin/dispatch/request/assign/driver/";
export const DISPATCHER_DASHBOARD_COUNT = "/admin/dispatch";

// bird eye view

export const BIRD_EYE_VIEW_DRIVER = "/admin/birdeyeview";
export const BIRD_EYE_VIEW_ORDER = "/admin/birdeyeview/orders";
export const BIRD_EYE_VIEW_ASSIGN_ORDER = "/admin/birdeyeview/assign";


// Customers URLs
export const API_GET_CUSTOMERS_URL = "/admin/getUsersWithFilter/";
export const API_GET_CUSTOMER = "/admin/getUserById/";
export const API_ADD_CUSTOMER = "/admin/addUser/";
export const API_EDIT_CUSTOMER = "/admin/editUser/";
export const API_DELETE_CUSTOMER = "/admin/removeUser/";
export const API_UPLOAD_CUSTOMER = "/user/importCsvList/";

// Restaurent Report

export const API_GET_RESTAURENTREPORT_URL =
  "/admin/driver/getAllReturantReport";

// Order URLs
export const API_GET_ORDERS_BY_CUSTOMERS_ID = "/admin/getUser/orders/";
export const API_GET_ORDERS_BY_STORE_ID = "/admin/getstore/orders/";

//Admin Setting URLs
export const API_GET_ALL_ADMINS = "/admin/getAllAdminList/";
export const API_EDIT_ADMIN = "/admin/editAdmin/";
export const API_ADD_ADMIN = "/admin/addAdmin/";
export const API_GET_ADMIN = "/admin/getUserById/";

// Content pages
export const API_GET_CONTENT_PAGES_URL = "/admin/contents/";
export const API_EDIT_CONTENT_PAGE = "/admin/contents/update";
export const API_GET_CONTENT_PAGE_BY_ID = "/admin/contents/edit/";

// Faq
export const API_GET_ALL_FAQ = "/admin/getAllFaqs/";
export const API_ADD_FAQ = "/admin/addFaq/";
export const API_EDIT_FAQ = "/admin/editFaq/";
export const API_GET_FAQ = "/admin/getFaqsById/";
export const API_DELETE_FAQ = "/admin/deleteFaq/";

///// Contact Query
export const API_GET_ALL_CONTACT_QUERY = "/admin/getcontactwithfilter";

//Mail Templates Url
export const API_GET_MAIL_TEMPLATES = "/adminSetting/getMailTitle/";
export const API_GET_MAIL_TEMPLATE = "/adminSetting/getMailTemplateById/";
export const API_ADD_MAIL_TEMPLATE = "/adminSetting/addMailTemplate/";
export const API_EDIT_MAIL_TEMPLATE = "/adminSetting/editMailTemplate/";

//Sms Templates Url
export const API_GET_SMS_TEMPLATES = "/adminSetting/getSmsTemplate/";
export const API_GET_SMS_TEMPLATE = "/adminSetting/getSmsTemplateById/";
export const API_ADD_SMS_TEMPLATE = "/adminSetting/addSmsTemplate/";
export const API_EDIT_SMS_TEMPLATE = "/adminSetting/editSmsTemplate/";

// Car Types Urls
export const API_GET_ALL_CAR_TYPES = "/admin/cartypes/";
export const API_GET_CAR_TYPE_BY_ID = "/admin/cartypes/edit/";
export const API_ADD_CAR_TYPE = "/admin/addcartypes/";
export const API_EDIT_CAR_TYPE = "/admin/updatecartype/";
export const API_DELETE_CARTYPE = "/admin/removecartypes";

// pricing
export const API_GET_ALL_PRICING = "/admin/pslist/";
export const API_GET_PRICING_BY_ID = "/admin/psdetails/";
export const API_ADD_PRICING = "/admin/addps/";
export const API_EDIT_PRICING = "/admin/updateps/";
export const API_REMOVE_PRICING = "/admin/removeps/";

// Trips URLsapi/v1/admin/orderdetails/
export const API_GET_ORDERS = "/adminDashboard/allTripsWithFilter/";
export const API_GET_ORDER_BY_ID = "/admin/order/";

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = "/adminSetting/getPromoCodeList/";
export const API_ADD_PROMOCODE = "/adminSetting/addPromoCode/";
export const API_EDIT_PROMOCODE = "/adminSetting/editPromocode/";
export const API_UPDATE_PROMOCODE_STATUS =
  "/adminSetting/updatePromocodeStatus/";
export const API_GET_PROMOCODE = "/adminSetting/getPromocodeDetails/";
export const API_DELETE_PROMOCODE = "/adminSetting/promocodeDelete/";

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification";
export const SEND_PUSH_NOTIFICATION_BY_MAIL = "admin/sendEmail";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "/adminSetting/changePassword/";
export const API_GET_BASIC_SETTINGS = "/adminSetting/getBasicsettinginfo";
export const API_UPDATE_BASIC_SETTINGS = "/adminSetting/basicAppSetting";
export const API_UPDATE_NOTIFICATION_SETTINGS =
  "/adminSetting/notificationSettingUpdate/";
export const API_UPDATE_SOCIAL_SETTINGS = "/adminSetting/basicSocialSetting";
export const API_INSTALLATION_SETTINGS = "/adminSetting/getConfigInfo";
export const API_UPDATE_SMS_INSTALLATION_SETTINGS =
  "/adminSetting/twilioUpdate";
export const API_UPDATE_PAYMENT_INSTALLATION_SETTINGS =
  "/adminSetting/PaymentConfigUpdate";
export const API_UPDATE_MAILGUN_INSTALLATION_SETTINGS =
  "/adminSetting/MailGunConfigUpdate";
export const API_UPDATE_ANDROID_INSTALLATION_SETTINGS =
  "/adminSetting/AndroidAppUrlUpdate";
export const API_UPDATE_IOS_INSTALLATION_SETTINGS =
  "/adminSetting/IOSAppURLUpdate";
export const API_GET_MAILTEMPLATE = "/adminSetting/getMailTitle";
export const API_UPDATE_MAILTEMPLATE = "/adminSetting/editMailTemplate";
export const API_GET_SMSTEMPALTE = "/adminSetting/getSmsTemplate";
export const API_UPDATE_SMSTEMPALTE = "/adminSetting/editSmsTemplate";

export const PERMISSIONS_ARRAY = [
  "dashboard",
  "drivers",
  "customers",
  "categories",
  "notes",
  "menu",
  "orders",
  "fresh_market",
  "items",
  "gif",
  "compare",
  "pricing",
  "promo_codes",
  "push_notification",
  "content_pages",
  "faq",
  "admin_settings",
  "basic_settings",
  "installation_setting",
  "mail_settings",
  "sms_settings",
  "vendor",
];

export const PERMISSIONS = PERMISSIONS_ARRAY.map((p) => {
  const name = p.replace("_", " ");
  return {
    [p]: name.charAt(0).toUpperCase() + name.slice(1),
  };
});

export const TIMEZONES = [
  {
    name: "(GMT-11:00) American Samoa",
    code: "Pacific/Pago_Pago",
  },
  {
    name: "(GMT-11:00) Midway Island",
    code: "Pacific/Midway",
  },
  {
    name: "(GMT-10:00) Hawaii",
    code: "Pacific/Honolulu",
  },
  {
    name: "(GMT-09:00) Alaska",
    code: "America/Juneau",
  },
  {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    code: "America/Los_Angeles",
  },
  {
    name: "(GMT-08:00) Tijuana",
    code: "America/Tijuana",
  },
  {
    name: "(GMT-07:00) Arizona",
    code: "America/Phoenix",
  },
  {
    name: "(GMT-07:00) Chihuahua",
    code: "America/Chihuahua",
  },
  {
    name: "(GMT-07:00) Mazatlan",
    code: "America/Mazatlan",
  },
  {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    code: "America/Denver",
  },
  {
    name: "(GMT-06:00) Central America",
    code: "America/Guatemala",
  },
  {
    name: "(GMT-06:00) Central Time (US & Canada)",
    code: "America/Chicago",
  },
  {
    name: "(GMT-06:00) Guadalajara, Mexico City",
    code: "America/Mexico_City",
  },
  {
    name: "(GMT-05:00) Tulum, Mexico",
    code: "America/Cancun",
  },
  {
    name: "(GMT-06:00) Monterrey",
    code: "America/Monterrey",
  },
  {
    name: "(GMT-06:00) Saskatchewan",
    code: "America/Regina",
  },
  {
    name: "(GMT-05:00) Bogota",
    code: "America/Bogota",
  },
  {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    code: "America/New_York",
  },
  {
    name: "(GMT-05:00) Indiana (East)",
    code: "America/Indiana/Indianapolis",
  },
  {
    name: "(GMT-05:00) Lima, Quito",
    code: "America/Lima",
  },
  {
    name: "(GMT-04:00) Atlantic Time (Canada)",
    code: "America/Halifax",
  },
  {
    name: "(GMT-04:00) Caracas",
    code: "America/Caracas",
  },
  {
    name: "(GMT-04:00) Georgetown",
    code: "America/Guyana",
  },
  {
    name: "(GMT-04:00) La Paz",
    code: "America/La_Paz",
  },
  {
    name: "(GMT-04:00) Puerto Rico",
    code: "America/Puerto_Rico",
  },
  {
    name: "(GMT-04:00) Santiago",
    code: "America/Santiago",
  },
  {
    name: "(GMT-03:30) Newfoundland",
    code: "America/St_Johns",
  },
  {
    name: "(GMT-03:00) Brasilia",
    code: "America/Sao_Paulo",
  },
  {
    name: "(GMT-03:00) Buenos Aires",
    code: "America/Argentina/Buenos_Aires",
  },
  {
    name: "(GMT-03:00) Greenland",
    code: "America/Godthab",
  },
  {
    name: "(GMT-03:00) Montevideo",
    code: "America/Montevideo",
  },
  {
    name: "(GMT-02:00) Mid-Atlantic",
    code: "Atlantic/South_Georgia",
  },
  {
    name: "(GMT-01:00) Azores",
    code: "Atlantic/Azores",
  },
  {
    name: "(GMT-01:00) Cape Verde Is.",
    code: "Atlantic/Cape_Verde",
  },
  {
    name: "(GMT+00:00) Edinburgh, London",
    code: "Europe/London",
  },
  {
    name: "(GMT+00:00) Lisbon",
    code: "Europe/Lisbon",
  },
  {
    name: "(GMT+00:00) Monrovia",
    code: "Africa/Monrovia",
  },
  {
    name: "(GMT+00:00) UTC",
    code: "Etc/UTC",
  },
  {
    name: "(GMT+01:00) Amsterdam",
    code: "Europe/Amsterdam",
  },
  {
    name: "(GMT+01:00) Belgrade",
    code: "Europe/Belgrade",
  },
  {
    name: "(GMT+01:00) Berlin",
    code: "Europe/Berlin",
  },
  {
    name: "(GMT+01:00) Bern, Zurich",
    code: "Europe/Zurich",
  },
  {
    name: "(GMT+01:00) Bratislava",
    code: "Europe/Bratislava",
  },
  {
    name: "(GMT+01:00) Brussels",
    code: "Europe/Brussels",
  },
  {
    name: "(GMT+01:00) Budapest",
    code: "Europe/Budapest",
  },
  {
    name: "(GMT+01:00) Casablanca",
    code: "Africa/Casablanca",
  },
  {
    name: "(GMT+01:00) Copenhagen",
    code: "Europe/Copenhagen",
  },
  {
    name: "(GMT+00:00) Dublin",
    code: "Europe/Dublin",
  },
  {
    name: "(GMT+01:00) Ljubljana",
    code: "Europe/Ljubljana",
  },
  {
    name: "(GMT+01:00) Madrid",
    code: "Europe/Madrid",
  },
  {
    name: "(GMT+01:00) Paris",
    code: "Europe/Paris",
  },
  {
    name: "(GMT+01:00) Prague",
    code: "Europe/Prague",
  },
  {
    name: "(GMT+01:00) Rome",
    code: "Europe/Rome",
  },
  {
    name: "(GMT+01:00) Sarajevo",
    code: "Europe/Sarajevo",
  },
  {
    name: "(GMT+01:00) Skopje",
    code: "Europe/Skopje",
  },
  {
    name: "(GMT+01:00) Stockholm",
    code: "Europe/Stockholm",
  },
  {
    name: "(GMT+01:00) Vienna",
    code: "Europe/Vienna",
  },
  {
    name: "(GMT+01:00) Warsaw",
    code: "Europe/Warsaw",
  },
  {
    name: "(GMT+01:00) West Central Africa",
    code: "Africa/Algiers",
  },
  {
    name: "(GMT+01:00) Zagreb",
    code: "Europe/Zagreb",
  },
  {
    name: "(GMT+02:00) Athens",
    code: "Europe/Athens",
  },
  {
    name: "(GMT+02:00) Bucharest",
    code: "Europe/Bucharest",
  },
  {
    name: "(GMT+02:00) Central Africa Time",
    code: "Africa",
  },
  {
    name: "(GMT+02:00) Freetown",
    code: "Africa/Freetown",
  },
  {
    name: "(GMT+02:00) Central Africa Time",
    code: "Africa",
  },
  {
    name: "(GMT+02:00) Johannesburg",
    code: "Africa/Johannesburg",
  },
  {
    name: "(GMT+02:00) Cairo",
    code: "Africa/Cairo",
  },
  {
    name: "(GMT+02:00) Harare",
    code: "Africa/Harare",
  },
  {
    name: "(GMT+02:00) Helsinki",
    code: "Europe/Helsinki",
  },
  {
    name: "(GMT+02:00) Jerusalem",
    code: "Asia/Jerusalem",
  },
  {
    name: "(GMT+02:00) Kaliningrad",
    code: "Europe/Kaliningrad",
  },
  {
    name: "(GMT+02:00) Kyiv",
    code: "Europe/Kiev",
  },
  {
    name: "(GMT+02:00) Pretoria",
    code: "Africa/Johannesburg",
  },
  {
    name: "(GMT+02:00) Riga",
    code: "Europe/Riga",
  },
  {
    name: "(GMT+02:00) Sofia",
    code: "Europe/Sofia",
  },
  {
    name: "(GMT+02:00) Tallinn",
    code: "Europe/Tallinn",
  },
  {
    name: "(GMT+02:00) Vilnius",
    code: "Europe/Vilnius",
  },
  {
    name: "(GMT+03:00) Baghdad",
    code: "Asia/Baghdad",
  },
  {
    name: "(GMT+03:00) Istanbul",
    code: "Europe/Istanbul",
  },
  {
    name: "(GMT+03:00) Kuwait",
    code: "Asia/Kuwait",
  },
  {
    name: "(GMT+03:00) Minsk",
    code: "Europe/Minsk",
  },
  {
    name: "(GMT+03:00) Moscow, St. Petersburg",
    code: "Europe/Moscow",
  },
  {
    name: "(GMT+03:00) Nairobi",
    code: "Africa/Nairobi",
  },
  {
    name: "(GMT+03:00) Riyadh",
    code: "Asia/Riyadh",
  },
  {
    name: "(GMT+03:30) Tehran",
    code: "Asia/Tehran",
  },
  {
    name: "(GMT+04:00) Abu Dhabi, Muscat",
    code: "Asia/Muscat",
  },
  {
    name: "(GMT+04:00) Baku",
    code: "Asia/Baku",
  },
  {
    name: "(GMT+04:00) Samara",
    code: "Europe/Samara",
  },
  {
    name: "(GMT+04:00) Tbilisi",
    code: "Asia/Tbilisi",
  },
  {
    name: "(GMT+03:00) Volgograd",
    code: "Europe/Volgograd",
  },
  {
    name: "(GMT+04:00) Yerevan",
    code: "Asia/Yerevan",
  },
  {
    name: "(GMT+04:30) Kabul",
    code: "Asia/Kabul",
  },
  {
    name: "(GMT+05:00) Ekaterinburg",
    code: "Asia/Yekaterinburg",
  },
  {
    name: "(GMT+05:00) Islamabad, Karachi",
    code: "Asia/Karachi",
  },
  {
    name: "(GMT+05:00) Tashkent",
    code: "Asia/Tashkent",
  },
  {
    name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    code: "Asia/Kolkata",
  },
  {
    name: "(GMT+05:30) Sri Jayawardenepura",
    code: "Asia/Colombo",
  },
  {
    name: "(GMT+05:45) Kathmandu",
    code: "Asia/Kathmandu",
  },
  {
    name: "(GMT+06:00) Almaty",
    code: "Asia/Almaty",
  },
  {
    name: "(GMT+06:00) Astana, Dhaka",
    code: "Asia/Dhaka",
  },
  {
    name: "(GMT+06:00) Urumqi",
    code: "Asia/Urumqi",
  },
  {
    name: "(GMT+06:30) Rangoon",
    code: "Asia/Rangoon",
  },
  {
    name: "(GMT+07:00) Bangkok, Hanoi",
    code: "Asia/Bangkok",
  },
  {
    name: "(GMT+07:00) Jakarta",
    code: "Asia/Jakarta",
  },
  {
    name: "(GMT+07:00) Krasnoyarsk",
    code: "Asia/Krasnoyarsk",
  },
  {
    name: "(GMT+07:00) Novosibirsk",
    code: "Asia/Novosibirsk",
  },
  {
    name: "(GMT+08:00) Beijing",
    code: "Asia/Shanghai",
  },
  {
    name: "(GMT+08:00) Chongqing",
    code: "Asia/Chongqing",
  },
  {
    name: "(GMT+08:00) Hong Kong",
    code: "Asia/Hong_Kong",
  },
  {
    name: "(GMT+08:00) Irkutsk",
    code: "Asia/Irkutsk",
  },
  {
    name: "(GMT+08:00) Kuala Lumpur",
    code: "Asia/Kuala_Lumpur",
  },
  {
    name: "(GMT+08:00) Perth",
    code: "Australia/Perth",
  },
  {
    name: "(GMT+08:00) Singapore",
    code: "Asia/Singapore",
  },
  {
    name: "(GMT+08:00) Taipei",
    code: "Asia/Taipei",
  },
  {
    name: "(GMT+08:00) Ulaanbaatar",
    code: "Asia/Ulaanbaatar",
  },
  {
    name: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    code: "Asia/Tokyo",
  },
  {
    name: "(GMT+09:00) Seoul",
    code: "Asia/Seoul",
  },
  {
    name: "(GMT+09:00) Yakutsk",
    code: "Asia/Yakutsk",
  },
  {
    name: "(GMT+09:30) Adelaide",
    code: "Australia/Adelaide",
  },
  {
    name: "(GMT+09:30) Darwin",
    code: "Australia/Darwin",
  },
  {
    name: "(GMT+10:00) Brisbane",
    code: "Australia/Brisbane",
  },
  {
    name: "(GMT+10:00) Canberra, Melbourne",
    code: "Australia/Melbourne",
  },
  {
    name: "(GMT+10:00) Guam",
    code: "Pacific/Guam",
  },
  {
    name: "(GMT+10:00) Hobart",
    code: "Australia/Hobart",
  },
  {
    name: "(GMT+10:00) Port Moresby",
    code: "Pacific/Port_Moresby",
  },
  {
    name: "(GMT+10:00) Sydney",
    code: "Australia/Sydney",
  },
  {
    name: "(GMT+10:00) Vladivostok",
    code: "Asia/Vladivostok",
  },
  {
    name: "(GMT+11:00) Magadan",
    code: "Asia/Magadan",
  },
  {
    name: "(GMT+11:00) New Caledonia",
    code: "Pacific/Noumea",
  },
  {
    name: "(GMT+11:00) Solomon Is.",
    code: "Pacific/Guadalcanal",
  },
  {
    name: "(GMT+11:00) Srednekolymsk",
    code: "Asia/Srednekolymsk",
  },
  {
    name: "(GMT+12:00) Auckland, Wellington",
    code: "Pacific/Auckland",
  },
  {
    name: "(GMT+12:00) Fiji",
    code: "Pacific/Fiji",
  },
  {
    name: "(GMT+12:00) Kamchatka",
    code: "Asia/Kamchatka",
  },
  {
    name: "(GMT+12:00) Marshall Is.",
    code: "Pacific/Majuro",
  },
  {
    name: "(GMT+12:45) Chatham Is.",
    code: "Pacific/Chatham",
  },
  {
    name: "(GMT+13:00) Nuku'alofa",
    code: "Pacific/Tongatapu",
  },
  {
    name: "(GMT+13:00) Samoa",
    code: "Pacific/Apia",
  },
  {
    name: "(GMT+13:00) Tokelau Is.",
    code: "Pacific/Fakaofo",
  },
];
