import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Reports = ({ match }) => (
  <div className="app-wrapper">
    {console.log("match_utl1111", match.url)}
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/listing`} />
      <Route
        path={`${match.url}/listing`}
        component={asyncComponent(() => import("./routes/listing"))}
      />
      <Route
        path={`${match.url}/mapView`}
        component={asyncComponent(() => import("./routes/mavView"))}
      />

      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Reports;
